<!-- 文章列表 -->
<template>
	<div class="bg-white px-3" style="margin: -20px;margin-top: -1rem;margin-bottom: 0!important;">
		<!-- 头部标签页 -->
		<el-tabs v-model="tabIndex" @tab-click="getList">
			<el-tab-pane :label="tab.name" :key="tabI" v-for="(tab,tabI) in tabbars"></el-tab-pane>
		</el-tabs>

		<button-search ref="buttonSearch" placeholder="要搜索的文章标题" @search="searchEvent">
			
			<!-- 左边 -->
			<template #left>
				<router-link :to="{name:'wenzhang_list_create'}" class="mr-2">
					<el-button type="success" size="mini">发布文章</el-button>
				</router-link>
				<el-button type="warning" size="mini" v-if="tab === 'delete'" @click="doEvent('restore')">恢复商品</el-button>
				<el-button type="danger" size="mini" v-if="tab === 'delete'" @click="doEvent('destroy')">彻底删除</el-button>
				<el-button type="danger" size="mini" v-if="tab != 'delete'" @click="deleteAll">批量删除</el-button>
				<el-button size="mini" v-if="tab === 'all' || tab === 'off'" @click="changeStatus(1)">上架</el-button>
				<el-button size="mini" v-if="tab === 'all' || tab === 'saling'" @click="changeStatus(0)">下架</el-button>
			</template>
			
			<!-- 高级搜索表单 -->
			<template #form>
				<el-form inline ref="form" :model="form" label-width="80px">
					<el-form-item label="文章标题" class="mb-0">
						<el-input v-model="form.title" placeholder="文章标题" size="mini"></el-input>
					</el-form-item>
					<el-form-item label="文章分类" class="mb-0">
						<el-select v-model="form.category_id" placeholder="请选择文章分类" size="mini">
							<el-option v-for="item in cateOptions" :key="item.id" :label="item | tree" :value="item.id">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item class="mb-0">
						<el-button type="info" size="mini" @click="searchEvent">搜索</el-button>
						<el-button size="mini" @click="clearSearch">清空筛选条件</el-button>
					</el-form-item>
				</el-form>
			</template>
		</button-search>

		<!-- 文章列表 -->
		<el-table border class="mt-3" :data="tableData" style="width: 100%" @selection-change="handleSelectionChange">
			<el-table-column type="selection" width="45" align="center">
			</el-table-column>
			
			<!-- 文章 -->
			<el-table-column label="文章" width="520">
				<template slot-scope="scope">
					<div class="media">
						<img class="mr-3" style="width: 80px;height: 80px; border-radius: 10px;" :src="scope.row.cover">
						<div class="media-body">
							<p class="mt-0 mb-0" style="width: 380px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">{{scope.row.title}}</p>
							<p class="mb-0" v-if="scope.row.category_id == 1">分类：行业资讯</p>
							<p class="mb-0" v-else>分类：公司新闻</p>
							<p class="mb-0">阅读：{{scope.row.yuedu}}</p>
							<p class="mb-0">时间：{{scope.row.create_time}}</p>
						</div>
					</div>
				</template>
			</el-table-column>
			
			<!-- 文章状态 -->
			<el-table-column prop="status" align="center" label="文章状态" width="180">
				<template slot-scope="scope">
					<span class="badge" :class="scope.row.status ? 'badge-success' : 'badge-danger'">{{scope.row.status ? '上架' : '仓库'}}</span>
				</template>
			</el-table-column>
			
			<!-- 审核状态 -->
			<el-table-column prop="status" align="center" width="240" label="审核状态" v-if="tab != 'delete'">
				<template slot-scope="scope">
					<div class="d-flex flex-column" v-if="!scope.row.ischeck">
						<el-button type="success" size="mini" @click="checkGoods(scope.row,1)" plain>审核通过
						</el-button>

						<el-button class="ml-0 mt-2" type="danger" size="mini" @click="checkGoods(scope.row,2)" plain>审核拒绝
						</el-button>
					</div>
					<span v-else>{{scope.row.ischeck === 1? '通过' : '拒绝'}}</span>
				</template>
			</el-table-column>
			
			<!-- 操作 -->
			<el-table-column align="center" label="操作">
				<template slot-scope="scope">
					<div v-if="tab != 'delete'">
						<el-button type="text" size="mini" @click="navigate('wenzhang_list_create',scope.row.id)">基础设置</el-button>
						<el-button type="text" size="mini" @click="navigate('wenzhang_list_content',scope.row.id)" :class="!scope.row.content ? 'text-danger' : ''">文章详情</el-button>
						<!-- <el-button type="text" size="mini" >折扣设置</el-button> -->
						<el-button type="text" size="mini" @click="deleteItem(scope.row)">删除文章</el-button>
					</div>
					<div v-else>暂无操作</div>
				</template>
			</el-table-column>
		</el-table>
		<div style="height: 60px;"></div>
		<el-footer class="border-top d-flex align-items-center px-0 position-fixed bg-white" style="bottom: 0;left: 200px;right: 0;z-index: 100;">
			<div style="flex: 1;" class="px-2">
				<el-pagination :current-page="page.current" :page-sizes="page.sizes" :page-size="page.size" layout="total, sizes, prev, pager, next, jumper"
				 :total="page.total" @size-change="handleSizeChange" @current-change="handleCurrentChange">
				</el-pagination>
			</div>
		</el-footer>

	</div>
</template>

<script>
	import buttonSearch from '@/components/common/button-search.vue'; // 引入 按钮、搜索、高级搜索组件
	import common from '@/common/mixins/common.js'; // 引入 全局共公样式
	export default {
		mixins: [common], // 引入mixins里的common.js
		inject: ['layout'], // 依赖注入
		// 引入注册
		components: {
			buttonSearch
		},
		data() {
			return {
				preUrl: "wenzhang", // api

				tabIndex: 0,
				tabbars: [
					{name: "全部",key: "all"},
					{name: "审核中",key: "checking"},
					{name: "发布中",key: "saling"},
					{name: "已下架",key: "off"},
					{name: "回收站",key: "delete"},
				],
				form: {
					title: "", //文章名称
					category_id: "" //文章分类
				},
				tableData: [],
				cateOptions: []
			}
		},
		filters: {
			tree(item) {
				if (item.level == 0) {
					return item.name
				}
				let str = ''
				for (let i = 0; i < item.level; i++) {
					str += i == 0 ? '|--' : '--'
				}
				return str + ' ' + item.name;
			}
		},
		// 计算属性
		computed: {
			// 订单类型
			tab() {
				return this.tabbars[this.tabIndex].key //拿到key值，用来高级搜索
			},
			params() {
				let str = ''
				for (let key in this.form) {
					let val = this.form[key]
					if (val) {
						str += `&${key}=${this.form[key]}`
					}
				}
				return str
			}
		},
		// 生命周期，调用__getData生成的数据
		created() {

		},
		// 生命周期监听页面
		methods: {
			// 调转基础设置、文章规格、文章属性、媒体设置、文章详情页面
			navigate(name, id) {
				this.$router.push({
					name,
					params: { id }
				})
			},
			// 获取请求列表分页url
			getListUrl() {
				return `/admin/${this.preUrl}/${this.page.current}?limit=${this.page.size}&tab=${this.tab}${this.params}`
			},
			// 获取列表 可修改
			getListResult(e) {
				console.log(e);
				this.tableData = e.list
				this.cateOptions = e.cates
			},
			// 上下架
			changeStatus(item) {
				item.status = item.status === 1 ? 0 : 1
			},
			// 清空筛选条件
			clearSearch() {
				this.form = {
					title: "",
					category_id: ""
				}
			},
			// 搜索事件
			searchEvent(e = false) {
				// 简单搜索
				if (typeof e === 'string') {
					this.form.title = e
				}
				// 高级搜索
				this.getList()
			},
			// 删除商品/恢复商品
			doEvent(key) {
				if (this.ids.length === 0) {
					return this.$message({
						type: "error",
						message: "请选择要操作的商品"
					})
				}
				this.showLoading()
				this.axios.post('/admin/wenzhang/' + key, { //删除商品/恢复商品api（restore、destroy区别 删除/回复）
					ids: this.ids
				}, {
					token: true
				}).then(res => {
					this.$message({
						type: "success",
						message: "操作成功"
					})
					this.getList()
					this.hideLoading()
				}).catch(err => {
					this.hideLoading()
				})
			},
			// 上下架
			changeStatus(status) {
				if (this.ids.length === 0) {
					return this.$message({
						type: "error",
						message: "请选择要操作的文章"
					})
				}
				this.showLoading()
				this.axios.post('/admin/wenzhang/changestatus', { //文章上下架api
					ids: this.ids,
					status
				}, {
					token: true
				}).then(res => {
					this.$message({
						type: "success",
						message: "操作成功"
					})
					this.getList()
					this.hideLoading()
				}).catch(err => {
					this.hideLoading()
				})
			},
			// 审核文章 ischeck审核 1同意 2拒绝
			checkGoods(item, ischeck) {
				this.layout.showLoading()
				this.axios.post('/admin/wenzhang/' + item.id + '/check', { //审核文章 api
					ischeck
				}, {
					token: true
				}).then(res => {
					this.getList()
					this.$message({
						type: "success",
						message: "操作成功"
					})
					this.layout.hideLoading()
				}).catch(err => {
					this.layout.hideLoading()
				})
			},
		},
	}
</script>

<style>
</style>
